.experience-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20% 0;
  padding: 0;
}

.experience-cards {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.experience-card {
    width: 20rem;
    height: 35rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem;
    padding: 1rem 0rem;
    border: 1px solid #22223B;
    border-radius: 2rem;
    transition: 0.3s ease;
    position: relative;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

.experience-card:hover {
    border: solid 1px #758BFD;
    box-shadow: #758BFD 0px 0px 10px 0px;
    cursor: pointer;
}

.experience-card.flipped {
    transform: rotateY(180deg);
}

.experience-card .front,
.experience-card .back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.experience-card .back {
    transform: rotateY(180deg);
}

.experience-card .skill-images {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 75%;
    width: 90%;
}

.experience-card .skill-images img {
    width: 3.5rem;
    height: 3.5rem;
    object-fit: contain;
    object-position: center;
    padding: 0;
    margin: 0.5rem;
}



@media screen and (min-width: 800px) { 
    .experience-card {
        width: 30rem;
        height: 30rem;
    }
    .experience-card .skill-images img {
        width: 4rem;
        height: 4rem;
    }
    .experience-card li {
        font-size: 1rem !important;
    }
}


.company-logo {
    width: 15rem;
    height: 3rem;
    object-fit: contain;
    object-position: center;
    padding: 1rem;
}

.front h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
}

.front h4 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
}

.experience-card li {
    font-size: .9rem;
    font-weight: 400;
    margin: 0;
    padding: .1rem .5rem;
}

