.skills-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 20% 0;
}

.skills {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: auto;
    max-width: 60rem;
}

.skill {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 2rem 1.5rem;
    width: 40px;
    height: 40px;
    border-radius: 10%;
    transition: all 0.2s ease-in-out;
}

@media screen and (min-width: 800px) {
    .skill {
        width: 100px;
        height: 100px;
    }
    .skill h3 {
        font-size: 1.5rem !important;
    }
    .skills-tabs {
        flex-direction: row !important;
    }
}

.skill img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.skill h3 {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    color: #22223B
}

.skills-tabs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.skills-tabs button {
    margin: 0;
    padding: 0.75rem;
    border: none;
    background-color: #F1F2F6;
    color: #22223B;
    font-size: 1.5rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
}

.skills-tabs button.active {
    color: #758BFD;
}

.skills-tabs button:hover {
    cursor: pointer;
    transform: scale(1.05);
}