.home-page {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    margin: 10% 0 0; /* centers main content */

}

.home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
}

.name-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}


.home-container h1 {
    font-size: 3.2rem;
    font-weight: 600;
    margin: 0.25rem;
    padding: 0;
    color: #22223B;
}

.home-container h2 {
    font-size: 2rem;
    font-weight: 600;
    margin: 0.25rem;
    padding: 0;
    color: #38A3A5;
}
.home-container h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
    padding: 0;
    color: #22223B;
}

.home-container h4 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0.25rem;
    padding: 0;
    color: #758BFD;
}

.home-container svg {
    color: #22223B;
}

.home-container .contact {
    width: 15rem;
    height: 6rem;
    padding: 0;
    margin: 1rem 0 0;
}

.portrait {
    border-radius: 50%;
    object-fit: cover;
    max-width: 25rem;
    height: auto;
    width: 100%;
    transition: 0.2s ease;
    padding: 0;
    margin: 2rem;
    background-color: #38A3A5;
}

.home-page .resume-button {
    border-color: #22223B;
    color: #22223B;
}

.home-page .resume-button:hover {
    border: 2px solid #758BFD;
    cursor: pointer;
    color: #758BFD;
}

/* .portrait:hover {
    transform: scale(1.1);
    filter: none;
} */

/* spacer only appears when portrait and home container are in a row */
@media screen and (min-width: 1015px) { 
    .home-spacer {
        width: 100%;
        height: 5rem;
        margin: 0;
        padding: 0;
    }
}