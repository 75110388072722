.appbar {
    top: 0;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(34, 34, 59, 0.95);
    color: #F1F2F6;
    position: fixed;
    padding: 0;
    width: 100%;
    height: 3rem;
    overflow: hidden;
}

.name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #F1F2F6;
    margin: 0;
    padding: 1rem;
    z-index: 3000;
    transition: top 0.4s ease-in-out;
    top: -10rem;
    position: relative;
}

.name.active {
    top: 0;

}

.appbar .MuiIconButton-root {
    color: #fff;
    z-index: 3000;
    padding: 1rem;
}

/* Navigation.css */
.appbar .desktopTabs {
    display: none;
}

.desktopContact {
    display: none;
}

@media screen and (min-width: 800px) { /* adjust breakpoint as needed */
    .appbar {
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgba(34, 34, 59, 1);;
        position: fixed;
        right: 0;
        height: 100%;
        width: 12rem;
    }
    
    .appbar .desktopTabs {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .appbar .MuiIconButton-root {
        display: none;
    }
    .appbar .drawer {
        display: none;
    }

    .desktopContact {
        display: flex;
        position: relative;
        transition: bottom 0.4s ease-in-out;
        bottom: -10rem;
    }
    

    .desktopContact.active {
        bottom: 0;
    }

}
  
.tab {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 600;
    transition: all 0.2s ease;
    padding: 10px 10px 10px 0;
}

.tab:hover {
    cursor: pointer;
}

.tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Drawer CSS */
.drawer {
    position: fixed;
    top: 0;
    right: -12rem;
    width: 12rem;
    height: 100%;
    background: #22223B;
    transition: right 0.3s ease;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .drawer.open {
    right: 0;  /* Shown */
  }

  .appbar svg {
    color: #F1F2F6;
  }
  .appbar .contact {
    width: 10rem;
    height: 5rem;
    margin-bottom: 0.5rem;
  }
  
  .blur {
    display: none;
  }

  .blur.active {
    display: block;
    position: fixed;
    top: 3rem;
    right: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    z-index: 500 !important;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .mobile-header-spacer {
    opacity: 0;
    height: 3rem;
    top: 0;
  }