.about-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20% 0;
    /* height: 35rem; */
}

.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #E76F51; */
    width: 100%;
    margin: 0;
}

.about-page p {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    padding: 1rem;
    text-align: center;
    width: auto;
    max-width: 55rem;
    height: auto;
    color: #22223B;

}

.about-container a {
    color: #38A3A5;
    white-space: nowrap;
}

b {
    font-weight: 600;
    color: #758BFD;
}

/* about-page invisible by default */
.invisible {
    opacity: 0;
}

/* causes about-page to fade in when scrolled to */
.fade-in {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}