.projects-page {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 20% 0;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    max-width: 60rem;
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
    border: solid 1px #22223B;
    border-radius: 1rem;
    transition: all 0.2s ease-in-out;
    width: 15rem;
    height: 30rem;
}

.project-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
}

.project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 0;
    padding: 0;
    width: 100%;
    height: 3rem;
}

.project-image {
    width: 15rem;
    height: 15rem;
    object-fit: contain;
    margin: 0;
    padding: 0;
    border-radius: 1rem;
}

.project-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: #22223B;
}

.project-description {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    color: #22223B;
    text-align: start;
}

.project-github svg {
    fill: #22223B;
}

.project-github:hover svg {
    fill: #758BFD;
}

.project .skill-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
}

.project .skill-images img {
    width: 10%;
    height: auto;
    border-radius: 10%;
    transition: all 0.2s ease-in-out;
    margin: .25rem;
}

.project-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.project-link svg {
    fill: #22223B;
}

.project-link:hover svg {
    fill: #758BFD;
}