.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.contact-item {
    text-decoration: none;
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
    transition: all 0.2s ease;
    flex-grow: 1;  /* This will make it expand */
    text-align: center; /* To center the SVG */
}

.contact-item svg {
    width: 50%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.contact-item:hover {
    transform: scale(1.1);
}

.resume-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 2px solid #F1F2F6;
    border-radius: 0.5rem;
    padding: 0.25rem;
    width: 75%;
    font-size: 1.5rem;
    font-weight: 600;
    color: #f6f1f3;
    text-decoration: none;
    transition: all 0.2s ease;
}

.resume-button:hover {
    border: 2px solid #758BFD;
    cursor: pointer;
    color: #758BFD;
    transform: scale(1.1);
}

.contact-item:hover svg {
    color: #758BFD;
}

.resume-button:hover svg {
    color: #758BFD;
}
