@media screen and (min-width: 800px) {
    .app {
        margin-right: 12rem;
        margin-top: 0rem !important;
    }
    

}

.app {
    margin-top: 3rem;
}

h1 {
    font-size: 3rem;
    font-weight: 600;
    margin: 1rem;
    padding: 0;
    color: #22223B;
}