.footer {
    width: 100%;
    height: 8rem;
    background-color: #22223B;
}

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: .5rem;
}

.footer-container svg {
    color: #F1F2F6;
}

.footer-container .contact {
    width: 15rem;
    height: 6rem;
    padding: 0;
    margin: 1rem 0 0;
}

.top-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #758BFD;
    border: none;
    color: #F1F2F6;
    font-size: 2rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 800px) {
    .footer {
        display: none;
    }
}